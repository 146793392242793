import React, { useRef, useState, useEffect } from 'react'
// Primereact
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { FilterMatchMode, FilterService } from 'primereact/api'
// Libraries
import toast from 'react-hot-toast'
import Tippy from '@tippyjs/react'
// Components
import RowExpansionTemplate from '../../components/PlaylistExpansion'
// Data
import { languageOptions, columnList, todoOptions } from './data'
// Hooks
import useLocalStorage from 'use-local-storage'
import { flagColumnFormat, growthColumnFormat, dataColor, followerFormatting } from '../../hooks/usePlaylistTableFormatting'
import { fireSort, flagSort, followerSort, LFRSort, newlyActiveSort, reverseSort, seoNameSort, starSort } from '../../hooks/useColumnSorts'
import { growthFactor, flagFactor, generateUpdateList } from '../../hooks/usePlaylistStats'
// Utilities
// import debounce from 'lodash.debounce'
import { putIt } from '../../utilities/network'
import { ChildrenWithRef } from '../../utilities/ChildrenWithRef'
import numberWithCommas from '../../utilities/numberWithCommas'
import getAcronym from '../../utilities/getAcronym'
// Icons
import { BsCalendar3Event, BsCalendar3Week, BsCalendar3, BsHeart, BsFlagFill, BsStarFill, BsStar, BsTags, BsLayers, BsCheckSquare, BsMegaphone } from 'react-icons/bs'
import { IoMusicalNotesOutline } from 'react-icons/io5'
import { FiUsers } from 'react-icons/fi'
import { AiFillFire } from 'react-icons/ai'
import { MdOutlineStickyNote2 } from 'react-icons/md'
import { PiRanking } from 'react-icons/pi'
import { balancedScore, nameScore } from '../../utilities/seoIndex'
import { InputNumber } from 'primereact/inputnumber'
import BrilliantMove from '../../img/brilliant-move.png'

const arrayFilter = (value, filterValue) => {
	if (!filterValue) return true
	if (!value || value.length === 0) return false
	if (value.includes(filterValue)) return true
	return false
}

FilterService.register('custom_tags', arrayFilter)
FilterService.register('custom_todos', arrayFilter)

const Table = ({ playlists: dbPlaylists, tags, user, error, seoData }) => {
	const [playlists, setPlaylists] = useState([])
	const [globalFilter, setGlobalFilter] = useState(null)
	const [expandedRows, setExpandedRows] = useState(null)
	const [flagFactors, setflagFactors] = useState({})
	const [growthFactors, setGrowthFactors] = useState({})
	const [percentStats, setPercentStats] = useState(false)
	const [selectedColumns, setSelectedColumns] = useLocalStorage('selectedColumns', columnList)
	const [stars, setStars] = useLocalStorage('starredPlaylists', [])
	const [userList, setUserList] = useState([])
	const [filters, setFilters] = useState({
		owner_name: { value: null, matchMode: FilterMatchMode.EQUALS },
		lang: { value: null, matchMode: FilterMatchMode.EQUALS },
		batch: { value: '', matchMode: FilterMatchMode.CONTAINS },
		tags: { value: null, matchMode: FilterMatchMode.CUSTOM },
		todos: { value: null, matchMode: FilterMatchMode.CUSTOM }
	})

	const debounceLastKeystroke = useRef(0)

	const [filterDisplay, setFilterDisplay] = useLocalStorage('showFilters', 'row')

	const dt = useRef(null)
	const growthFactorList = useRef([])

	const filteredPlaylists = useRef([])

	useEffect(() => {
		// console.log(dbPlaylists?.length, tags?.length)
		if (!dbPlaylists || !tags) {
			return
		}
		// Calculate SEO scores
		const tempPlaylists = dbPlaylists.map(playlist => {
			if (!playlist?.tags || playlist.tags.length === 0) {
				return playlist
			}
			const tagsList = playlist.tags
			const playlistTagData = []
			// Get rank & count for this playlist for each of those tags
			tagsList.forEach(tag => {
				// Get all queries for each tag
				const masterTagInfo = tags.find(data => data.tag === tag)
				if (masterTagInfo && masterTagInfo?.queries) {
					masterTagInfo.queries.forEach(query => {
						const seoDataIndex = seoData.findIndex(data => data.query === query)
						if (seoDataIndex > -1) {
							// Get rank
							let tempRank = 200
							if (seoData[seoDataIndex]?.history && seoData[seoDataIndex].history.length === 1) {
								const rankIndex = seoData[seoDataIndex].history[0].results.findIndex(result => result.id === playlist.id)
								if (rankIndex > -1) {
									tempRank = seoData[seoDataIndex].history[0].results[rankIndex].rank
								}
							}
							playlistTagData.push({
								query: seoData[seoDataIndex].query,
								rank: tempRank,
								count: seoData[seoDataIndex]?.count || 1000
							})
						}
					})
				}
			})
			// Plug new array into balance score formula
			if (playlistTagData.length > 0) {
				playlist.seo_index = balancedScore(playlistTagData)
			}
			return playlist
		})

		setPlaylists(tempPlaylists)
		filteredPlaylists.current = tempPlaylists

		const tempLanguages = {}
		const tempGrowthFactors = {}
		const tempGrowthFactorList = []
		const tempflagFactors = {}
		const tempUserList = []
		for (let i = 0; i < dbPlaylists.length; i++) {
			const playlist = dbPlaylists[i]
			tempLanguages[playlist.id] = playlist.lang || 'en'

			// Get growth factors and flag factors
			const gf = growthFactor(playlist)
			const cf = flagFactor(playlist)
			tempGrowthFactors[playlist.id] = gf
			tempflagFactors[playlist.id] = cf
			if (gf.followersDiff > 0) {
				tempGrowthFactorList.push(gf.followersDiff)
			}
			// Add user to list
			if (tempUserList.indexOf(playlist.owner_name) === -1) {
				tempUserList.push(playlist.owner_name)
			}
		}
		const growthFloatArray = new Float64Array(tempGrowthFactorList)
		growthFactorList.current = growthFloatArray.sort().reverse()

		// setLanguages(tempLanguages)
		setGrowthFactors(tempGrowthFactors)
		setflagFactors(tempflagFactors)
		setUserList(tempUserList)
	}, [dbPlaylists, tags])

	const onColumnToggle = (event) => {
		const selectedColumns = event.value
		const orderedSelectedColumns = columnList.filter(col => selectedColumns.some(sCol => sCol.field === col.field))
		setSelectedColumns(orderedSelectedColumns)
	}

	// console.log('Test playlist', playlists.find(playlist => playlist.id === '2kkfjMbfd8TJczku0smbOO'))

	async function updatePlaylistProperty (id, field, value) {
		const langUpdate = putIt('/playlist', { id, field, value })
		toast.promise(langUpdate, {
			loading: `Updating ${field}...`,
			success: `${field} updated.`,
			error: 'Error when updating.'
		})
	}

	const header = (
		<div className="table-header">
			{user?.role ? user.role.toUpperCase() : ''}{' Playlists'}
			<div style={{ display: 'flex' }}>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText
						type="search"
						onInput={(e) => {
							setGlobalFilter(e.target.value)
						}}
						placeholder="Global Search"
					/>
				</span>
				<MultiSelect display='chip' value={selectedColumns} options={columnList} optionLabel="header" onChange={onColumnToggle} style={{ width: '20em', marginLeft: 10 }} />
				<Button
					className="p-button-secondary"
					type="button"
					label={percentStats ? '#' : '%'}
					style={{ marginLeft: 10 }}
					onClick={() => setPercentStats(!percentStats)}>
				</Button>
				<Button
					type="button"
					className="p-button-secondary"
					icon="pi pi-filter"
					style={{ marginLeft: 10 }}
					onClick={() => setFilterDisplay(filterDisplay === 'row' ? null : 'row')}>
				</Button>
				<Button
					type="button"
					className="p-button-secondary"
					icon="pi pi-external-link"
					label={'Get Update List'}
					style={{ marginLeft: 10 }}
					onClick={() => {
						const success = generateUpdateList(playlists)
						if (success) {
							toast.success('Successfully copied list!')
						} else {
							toast.error("Couldn't copy list.")
						}
					}}></Button>
				<Button
					type="button"
					className="p-button-secondary"
					icon="pi pi-cloud-download"
					label={'New playlists'}
					style={{ marginLeft: 10 }}
					onClick={() => {
						toast.success('Please use the button at the top right.')
					}}></Button>
			</div>
		</div >
	)

	const ownerNameFilterTemplate = (options) => {
		return <Dropdown value={options.value} options={userList} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Any" showClear />
	}

	const langFilterTemplate = (options) => {
		return <Dropdown value={options.value} options={languageOptions} onChange={(e) => {
			options.filterApplyCallback(e.value)
		}} placeholder="Any" showClear />
	}

	const debounceUpdate = (id, field, value) => {
		debounceLastKeystroke.current = Date.now()
		setTimeout(async () => {
			if (Date.now() - debounceLastKeystroke.current > 500) {
				await updatePlaylistProperty(id, field, value)
			}
		}, 500)
	}

	const tagFilterTemplate = (options) => {
		return <Dropdown value={options.value} options={tags ? tags.map(tag => ({ label: tag.tag, value: tag.tag })) : []} onChange={(e) => {
			options.filterApplyCallback(e.value)
		}} placeholder="Any" showClear />
	}

	const todoFilterTemplate = (options) => {
		return <Dropdown value={options.value} options={todoOptions ? todoOptions.map(todo => ({ label: todo, value: todo })) : []} onChange={(e) => {
			options.filterApplyCallback(e.value)
		}} placeholder="Any" showClear />
	}

	const newActivity = (rowData) => {
		// Last week had 0, last month had 0, this week or day has not zero
		const data = rowData.data.f_spotify
		return data.lastWeek.data < 2 && data.lastMonth.data < 2 && (data.today.data > 2)
	}

	return (
		<DataTable
			value={playlists || []}
			ref={dt}
			header={header}
			paginator
			rows={10} rowsPerPageOptions={[10, 20, 50, 100]}
			paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
			currentPageReportTemplate="{first} to {last} of {totalRecords}"
			removableSort
			resizableColumns
			reorderableColumns
			columnResizeMode="fit"
			sortField={'data.f_spotify.lastWeek'}
			sortOrder={-1}
			expandedRows={expandedRows}
			onRowToggle={(e) => {
				setExpandedRows(e.data)
			}}
			rowExpansionTemplate={rowData => <RowExpansionTemplate id={rowData.id} org={user.role} key={rowData.id} />}
			globalFilter={globalFilter}
			scrollable
			scrollHeight='flex'
			className="p-datatable-sm"
			// virtualScrollerOptions={{ itemSize: 46 }}
			emptyMessage={error ? 'Playlists failed to load - refresh to try again.' : 'Playlists will appear here when loaded.'}
			filters={filters}
			filterDisplay={filterDisplay}
			onFilter={(e) => {
				setFilters(e.filters)
			}}
			onValueChange={filteredData => {
				filteredPlaylists.current = filteredData
			}}
		>
			<Column expander style={{ maxWidth: 50 }} />
			{selectedColumns.findIndex(el => el.field === 'growth') > -1 &&
				<Column
					field='growth'
					header={<Tippy content="Week over month growth factor"><ChildrenWithRef><AiFillFire size={19} /></ChildrenWithRef></Tippy>}
					body={rowData => growthColumnFormat(rowData, growthFactors, growthFactorList)}
					sortable
					sortFunction={event => fireSort(event, [...filteredPlaylists.current], growthFactors)}
					style={{ maxWidth: 80 }}
				>
				</Column>
			}
			{selectedColumns.findIndex(el => el.field === 'flagFactor') > -1 &&
				<Column
					field='flagFactor'
					header={<Tippy content="Growth Flags"><ChildrenWithRef><BsFlagFill size={19} /></ChildrenWithRef></Tippy>}
					body={rowData => flagColumnFormat(rowData, flagFactors)}
					sortable
					sortFunction={event => flagSort(event, [...filteredPlaylists.current], flagFactors)}
					style={{ maxWidth: 70 }}
				>
				</Column>
			}
			{selectedColumns.findIndex(el => el.field === 'star') > -1 &&
				<Column
					field='star'
					header={<Tippy content="Starred"><ChildrenWithRef><BsStarFill size={19} /></ChildrenWithRef></Tippy>}
					body={rowData => <div style={{ cursor: 'pointer' }} onClick={() => {
						const newStars = [...stars]
						const index = newStars.indexOf(rowData.id)
						if (index > -1) {
							newStars.splice(index, 1)
						} else {
							newStars.push(rowData.id)
						}
						setStars(newStars)
					}}>
						{
							stars.indexOf(rowData.id) > -1 ? <BsStarFill size={19} /> : <BsStar size={19} />
						}
					</div>}
					sortable
					sortFunction={event => starSort(event, [...filteredPlaylists.current], [...stars])}
					style={{ maxWidth: 70 }}
				></Column>
			}
			{selectedColumns.findIndex(el => el.field === 'newly-active') > -1 &&
				<Column
					field='newly-active'
					header={<Tippy content="Newly Active"><ChildrenWithRef>
						<img src={BrilliantMove} width='20px' style={{ marginTop: 4 }} />
					</ChildrenWithRef></Tippy>}
					body={rowData => <div style={{ cursor: 'pointer' }}>
						{
							newActivity(rowData) &&
							<img src={BrilliantMove} width='20px' style={{ marginTop: 4 }} />
						}
					</div>}
					sortable
					sortFunction={event => newlyActiveSort(event, [...filteredPlaylists.current], newActivity)}
					style={{ maxWidth: 70 }}
				></Column>
			}
			{selectedColumns.findIndex(el => el.field === 'rating') > -1 &&
				<Column
					field='rating'
					header={<Tippy content="Rating (1-10)"><ChildrenWithRef><PiRanking size={19} /></ChildrenWithRef></Tippy>}
					body={rowData =>
						<div id='rating-column'>
							<InputNumber value={rowData?.rating} min={0} max={10} step={1}
								style={{ maxWidth: 50 }}
								onChange={async (e) => {
									if (e.value < 0) {
										e.value = 0
									} else if (e.value > 10) {
										e.value = 10
									}
									const newPlaylists = [...playlists]
									const index = newPlaylists.findIndex(playlist => playlist.id === rowData.id)
									newPlaylists[index].rating = e.value
									setPlaylists(newPlaylists)
									await updatePlaylistProperty(rowData.id, 'rating', e.value)
								}} />
						</div>
					}
					sortable
					style={{ maxWidth: 70 }}
				></Column>
			}
			{selectedColumns.findIndex(el => el.field === 'notes') > -1 &&
				<Column
					field='notes'
					header={<Tippy content="Notes attached"><ChildrenWithRef><MdOutlineStickyNote2 size={21} /></ChildrenWithRef></Tippy>}
					body={rowData => <div>
						{
							rowData?.notes ? <Tippy content={rowData.notes} placement="right"><ChildrenWithRef><MdOutlineStickyNote2 size={21} /></ChildrenWithRef></Tippy> : <span></span>
						}
					</div>}
					style={{ maxWidth: 50 }}
				></Column>
			}
			<Column
				field="name"
				header="Playlist"
				body={(rowData) => (
					<a
						href={`https://open.spotify.com/playlist/${rowData.id}`}
						target="_blank"
						rel="noreferrer">
						{rowData.name}
					</a>
				)}
				style={{ minWidth: 250 }}
				sortable>
			</Column>
			{selectedColumns.findIndex(el => el.field === 'owner_name') > -1 &&
				<Column
					field="owner_name"
					header={<span>Owner</span>}
					filter filterElement={ownerNameFilterTemplate}
					showFilterMatchModes={false}
					showFilterMenu={false}
					body={(rowData) => (
						<a
							href={`https://open.spotify.com/user/${rowData.owner_id}`}
							target="_blank"
							rel="noreferrer">
							{rowData.owner_name.split(' ').length > 1 ? getAcronym(rowData.owner_name) : rowData.owner_name}
						</a>
					)}
					style={{ minWidth: 120 }}
				>
				</Column>
			}
			{
				selectedColumns.findIndex(el => el.field === 'todos') > -1 &&
				<Column field={'todos'}
					header={<Tippy content="To Dos"><ChildrenWithRef><BsCheckSquare className='middle' size={22} /> To Dos</ChildrenWithRef></Tippy>}
					filter filterElement={todoFilterTemplate}
					filterField="todos"
					showFilterMenu={false}
					body={rowData =>
						<MultiSelect style={{ width: '100%' }} display="chip" removeIcon={''} value={rowData?.todos ? rowData.todos.filter(todo => todoOptions.includes(todo)) : []} options={todoOptions || []}
							onChange={async (e) => {
								const newPlaylists = [...playlists]
								const index = newPlaylists.findIndex(playlist => playlist.id === rowData.id)
								newPlaylists[index].todos = e.value
								setPlaylists(newPlaylists)
								await updatePlaylistProperty(rowData.id, 'todos', e.value)
							}
							} />
					}
					style={{ maxWidth: 200 }}
				>
				</Column>
			}
			{selectedColumns.findIndex(el => el.field === 'lang') > -1 &&
				<Column field="lang" header="Language" style={{ maxWidth: 180 }}
					filterField="lang" showFilterMatchModes={false}
					filter filterElement={langFilterTemplate}
					showFilterMenu={false}
					body={(rowData) => <Dropdown value={rowData?.lang || ''} options={languageOptions} optionLabel="label" optionValue="value"
						onChange={async (e) => {
							const newPlaylists = [...playlists]
							const index = newPlaylists.findIndex(playlist => playlist.id === rowData.id)
							newPlaylists[index].lang = e.value
							setPlaylists(newPlaylists)
							await updatePlaylistProperty(rowData.id, 'lang', e.value)
						}
						} placeholder="None"
						style={{ position: 'relative', width: '100%' }}
					/>}
				></Column>
			}
			{
				selectedColumns.findIndex(el => el.field === 'tags') > -1 &&
				<Column field={'tags'}
					header={<Tippy content="Tags"><ChildrenWithRef><BsTags className='middle' size={22} /> Tags</ChildrenWithRef></Tippy>}
					filter filterElement={tagFilterTemplate}
					filterField="tags"
					showFilterMenu={false}
					body={rowData =>
						<MultiSelect style={{ width: '100%' }} display="chip" removeIcon={''} value={rowData?.tags ? rowData.tags.filter(tag => tags.findIndex(tag2 => tag === tag2.tag) > -1) : []} options={tags.map(tag => tag.tag) || []}
							onChange={async (e) => {
								const newPlaylists = [...playlists]
								const index = newPlaylists.findIndex(playlist => playlist.id === rowData.id)
								newPlaylists[index].tags = e.value
								setPlaylists(newPlaylists)
								await updatePlaylistProperty(rowData.id, 'tags', e.value)
							}
							} />
					}
					style={{ maxWidth: 200 }}
				>
				</Column>
			}
			{
				selectedColumns.findIndex(el => el.field === 'batch') > -1 &&
				<Column field={'batch'}
					header={<Tippy content="Batch"><ChildrenWithRef><BsLayers className='middle' size={22} /> Batch</ChildrenWithRef></Tippy>}
					filter
					showFilterMenu={false}
					body={rowData =>
						<InputText style={{ width: '100%' }} value={rowData?.batch ? rowData.batch : ''}
							onChange={async (e) => {
								const newPlaylists = [...playlists]
								const index = newPlaylists.findIndex(playlist => playlist.id === rowData.id)
								newPlaylists[index].batch = e.target.value
								setPlaylists(newPlaylists)
								debounceUpdate(rowData.id, 'batch', e.target.value)
							}
							} />
					}
					style={{ minWidth: 120 }}
				>
				</Column>
			}
			{
				selectedColumns.findIndex(el => el.field === 'streams') > -1 &&
				<Column field={'data.streams-7day.today.data'}
					header={rowData => <Tippy content="7 Day Streams"><ChildrenWithRef><IoMusicalNotesOutline className='middle' size={22} /></ChildrenWithRef></Tippy>}
					body={(rowData) => <span>{numberWithCommas(rowData.data['streams-7day'].today.data)}</span>}
					className="follower-column"
					style={{ maxWidth: 100 }}
					sortable
					sortFunction={event => reverseSort(event, [...filteredPlaylists.current])}
				>
				</Column>
			}
			{
				selectedColumns.findIndex(el => el.field === 'listeners') > -1 &&
				<Column field={'data.listeners-7day.today.data'}
					header={<Tippy content="7 Day Listeners"><ChildrenWithRef><FiUsers className='middle' size={22} /></ChildrenWithRef></Tippy>}
					body={(rowData) => <span>{numberWithCommas(rowData.data['listeners-7day'].today.data)}</span>}
					className="follower-column"
					style={{ maxWidth: 100 }}
					sortable
					sortFunction={event => reverseSort(event, [...filteredPlaylists.current])}
				>
				</Column>
			}
			{
				selectedColumns.findIndex(el => el.field === 'listeners') > -1 &&
				<Column field={'beans'}
					header={<Tippy content="Listener to Follower Ratio"><span>LFR</span></Tippy>}
					body={(rowData) => {
						const LFR = Math.floor(rowData.data['listeners-7day'].today.data / rowData.data.f_spotify.today.data * 1000) / 1000
						return <span>{isNaN(LFR) ? '-' : LFR}</span>
					}}
					className="follower-column"
					style={{ maxWidth: 100 }}
					sortable
					sortFunction={event => LFRSort(event, [...filteredPlaylists.current])} >
				</Column>
			}
			{
				selectedColumns.findIndex(el => el.field === 'f_spotify') > -1 &&
				<Column field={'data.f_spotify.today.data'}
					header={<Tippy content="Followers"><ChildrenWithRef><BsHeart size={20} /></ChildrenWithRef></Tippy>}
					body={(rowData) => <span className="tooltipped" data-pr-tooltip="7 Day Listeners" data-pr-position="top">{numberWithCommas(rowData.data.f_spotify.today.data)}</span>}
					className="follower-column"
					style={{ maxWidth: 100 }}
					sortable
					sortFunction={event => reverseSort(event, [...filteredPlaylists.current])}
				>
				</Column>
			}
			{
				selectedColumns.findIndex(el => el.field === 'ad_views') > -1 &&
				<Column
					field={'data.ad_views.today.data'}
					header={<Tippy content="Ad views yesterday"><ChildrenWithRef><BsMegaphone size={19} /></ChildrenWithRef></Tippy>}
					body={(rowData) => <div>
						{rowData.data.ad_views.today.data || ''}
					</div>}
					className="follower-column"
					style={{ maxWidth: 80 }}
					sortable
					sortFunction={event => reverseSort(event, [...filteredPlaylists.current])}
				></Column>
			}
			{
				selectedColumns.findIndex(el => el.field === 'ad_conv') > -1 &&
				<Column
					// field={'data.ad_views.yesterday.data'}
					header={<Tippy content="Ad conversion rate"><ChildrenWithRef>Conv. %</ChildrenWithRef></Tippy>}
					body={(rowData) => {
						// console.log(rowData)
						return (
							<Tippy placement='right' content={!rowData.data.ad_views.yesterday.data
								? ''
								: `${rowData.data.ad_views.today.data} clicks, ${(rowData.data.f_spotify.today.data - rowData.data.f_spotify.yesterday.data) || 0} new followers`}>
								<div>
									{!rowData.data.ad_views.yesterday.data
										? ''
										: `${Math.round((((rowData.data.f_spotify.today.data - rowData.data.f_spotify.yesterday.data) || 0) / rowData.data.ad_views.today.data) * 100)}%`
									}
								</div>
							</Tippy>
						)
					}}
					className="follower-column"
					style={{ maxWidth: 80 }}
					sortable
					sortFunction={event => followerSort(event, [...filteredPlaylists.current], percentStats)}
				></Column>

			}
			{
				selectedColumns.findIndex(el => el.field === 'followers_day') > -1 &&
				<Column
					field={'data.f_spotify.yesterday.data'}
					header={<Tippy content="Follower change since yesterday"><ChildrenWithRef><BsCalendar3Event size={19} /></ChildrenWithRef></Tippy>}
					body={(rowData) => <div style={{ color: dataColor(rowData, 'yesterday') }}>
						{followerFormatting(rowData, 'yesterday', percentStats)}
					</div>}
					className="follower-column"
					style={{ maxWidth: 80 }}
					sortable
					sortFunction={event => followerSort(event, [...filteredPlaylists.current], percentStats)}
				></Column>
			}
			{
				selectedColumns.findIndex(el => el.field === 'followers_week') > -1 &&
				<Column field={'data.f_spotify.lastWeek.data'}
					header={<Tippy content="Follower change since last week"><ChildrenWithRef><BsCalendar3Week size={19} /></ChildrenWithRef></Tippy>}
					body={(rowData) => <div style={{ color: dataColor(rowData, 'lastWeek') }}>
						{followerFormatting(rowData, 'lastWeek', percentStats)}
					</div>}
					className="follower-column"
					style={{ maxWidth: 80 }}
					sortable
					sortFunction={event => followerSort(event, [...filteredPlaylists.current], percentStats)}
				></Column>
			}
			{
				selectedColumns.findIndex(el => el.field === 'followers_month') > -1 &&
				<Column
					field={'data.f_spotify.lastMonth.data'}
					header={<Tippy content="Follower change since last month"><ChildrenWithRef><BsCalendar3 size={19} /></ChildrenWithRef></Tippy>}
					body={(rowData) => <div style={{ color: dataColor(rowData, 'lastMonth') }}>
						{followerFormatting(rowData, 'lastMonth', percentStats)}
					</div>}
					className="follower-column"
					style={{ maxWidth: 80 }}
					sortable
					sortFunction={event => followerSort(event, [...filteredPlaylists.current], percentStats)}
				></Column>
			}
			{
				selectedColumns.findIndex(el => el.field === 'seo_name') > -1 &&
				<Column
					field={'data.spotify_seo_name'}
					header='Name SEO'
					body={rowData => {
						const newData = rowData?.data?.spotify_seo_name
						if (newData && newData.length > 0) {
							const data = newData[0].data
							return <Tippy allowHTML={true} content={<span>Rank: {data?.rank || '0'}<br />Count: {data?.count}</span>}><ChildrenWithRef>{nameScore(data)}</ChildrenWithRef></Tippy>
						}
						return '-'
					}}
					className="follower-column"
					style={{ maxWidth: 150 }}
					sortable
					sortFunction={event => seoNameSort(event, [...filteredPlaylists.current])}
				></Column>
			}
			{
				selectedColumns.findIndex(el => el.field === 'SEO Index') > -1 &&
				<Column
					field={'seo_index'}
					header='SEO Index'
					className="follower-column"
					style={{ maxWidth: 150 }}
					sortable
				></Column>
			}
			{
				selectedColumns.findIndex(el => el.field === 'last_updated') > -1 &&
				<Column
					field="last_updated"
					header="Last Updated"
					style={{ maxWidth: 200 }}
					body={
						rowData => {
							const lastUpdatedDate = new Date(rowData.last_updated)
							return <span>{Math.floor((Date.now() - lastUpdatedDate.getTime()) / (1000 * 3600 * 24))} days ago</span>
						}
					}
					sortable>
				</Column>
			}
		</DataTable >
	)
}

export default Table
