import { nameScore } from '../utilities/seoIndex'

export function flagSort (event, playlists, flagFactors) {
	playlists.sort((data1, data2) => {
		const data1Growth = data1.id in flagFactors ? flagFactors[data1.id].length : NaN
		const data2Growth = data2.id in flagFactors ? flagFactors[data2.id].length : NaN
		let result = null

		if (isNaN(data1Growth) && !isNaN(data2Growth)) {
			result = 1
		} else if (!isNaN(data1Growth) && isNaN(data2Growth)) {
			result = -1
		} else if (isNaN(data1Growth) && isNaN(data2Growth)) {
			result = 0
		} else {
			result = (data1Growth < data2Growth) ? 1 : (data1Growth > data2Growth) ? -1 : 0
		}
		return (event.order * result)
	})

	return playlists
}

export function reverseSort (event, playlists) {
	playlists.sort((data1, data2) => {
		const objLevels = event.field.split('.')
		let value1 = data1
		let value2 = data2
		for (const level of objLevels) {
			value1 = value1[level]
			value2 = value2[level]
		}
		let result = null

		if (isNaN(value1) && !isNaN(value2)) {
			result = 1
		} else if (!isNaN(value1) && isNaN(value2)) {
			result = -1
		} else if (isNaN(value1) && isNaN(value2)) {
			result = 0
		} else {
			result = (value1 < value2) ? 1 : (value1 > value2) ? -1 : 0
		}
		return (event.order * result)
	})

	return playlists
}

export function followerSort (event, playlists, percentStats) {
	playlists.sort((data1, data2) => {
		const objLevels = event.field.split('.')
		let value1 = data1
		const value1Today = value1.data.f_spotify.today.data
		let value2 = data2
		const value2Today = value2.data.f_spotify.today.data
		for (const level of objLevels) {
			value1 = value1[level]
			value2 = value2[level]
		}
		value1 = value1Today - value1
		value2 = value2Today - value2
		let result = null

		if (isNaN(value1) && !isNaN(value2)) {
			result = 1
		} else if (!isNaN(value1) && isNaN(value2)) {
			result = -1
		} else if (isNaN(value1) && isNaN(value2)) {
			result = 0
		} else {
			if (percentStats) {
				result = (value1 / (value1Today - value1) < value2 / (value2Today - value2)) ? 1 : (value1 / (value1Today - value1) > value2 / (value2Today - value2)) ? -1 : 0
			} else {
				result = (value1 < value2) ? 1 : (value1 > value2) ? -1 : 0
			}
		}
		return (event.order * result)
	})

	return playlists
}

export function fireSort (event, playlists, growthFactors) {
	playlists.sort((data1, data2) => {
		const data1Growth = data1.id in growthFactors ? growthFactors[data1.id]?.followersDiff : NaN
		const data2Growth = data2.id in growthFactors ? growthFactors[data2.id]?.followersDiff : NaN
		let result = null

		if (isNaN(data1Growth) && !isNaN(data2Growth)) {
			result = 1
		} else if (!isNaN(data1Growth) && isNaN(data2Growth)) {
			result = -1
		} else if (isNaN(data1Growth) && isNaN(data2Growth)) {
			result = 0
		} else {
			result = (data1Growth < data2Growth) ? 1 : (data1Growth > data2Growth) ? -1 : 0
		}
		return (event.order * result)
	})

	return playlists
}

export function starSort (event, playlists, stars) {
	playlists.sort((data1, data2) => {
		const data1Star = stars.indexOf(data1.id) > -1
		const data2Star = stars.indexOf(data2.id) > -1
		let result = null

		if (isNaN(data1Star) && !isNaN(data2Star)) {
			result = 1
		} else if (!isNaN(data1Star) && isNaN(data2Star)) {
			result = -1
		} else if (isNaN(data1Star) && isNaN(data2Star)) {
			result = 0
		} else {
			result = (data1Star < data2Star) ? 1 : (data1Star > data2Star) ? -1 : 0
		}
		return (event.order * result)
	})

	return playlists
}

export function newlyActiveSort (event, playlists, hasNewActivity) {
	playlists.sort((data1, data2) => {
		const data1Star = hasNewActivity(data1)
		const data2Star = hasNewActivity(data2)
		let result = null

		if (data1Star && !data2Star) {
			result = 1
		} else if (!data1Star && data2Star) {
			result = -1
		} else if (data1Star && data2Star) {
			result = 0
		} else {
			result = (data1Star < data2Star) ? 1 : (data1Star > data2Star) ? -1 : 0
		}
		return (event.order * result)
	})

	return playlists
}

export function LFRSort (event, playlists) {
	playlists.sort((data1, data2) => {
		const data1Growth = Math.floor(data1.data['listeners-7day'].today.data / data1.data.f_spotify.today.data * 1000) / 1000
		const data2Growth = Math.floor(data2.data['listeners-7day'].today.data / data2.data.f_spotify.today.data * 1000) / 1000
		let result = null
		if (isNaN(data1Growth) && !isNaN(data2Growth)) {
			result = 1
		} else if (!isNaN(data1Growth) && isNaN(data2Growth)) {
			result = -1
		} else if (isNaN(data1Growth) && isNaN(data2Growth)) {
			result = 0
		} else {
			result = (data1Growth < data2Growth) ? 1 : (data1Growth > data2Growth) ? -1 : 0
		}
		return (event.order * result)
	})

	return playlists
}

export function seoNameSort (event, playlists) {
	playlists.sort((data1, data2) => {
		const value1 = data1?.data?.spotify_seo_name
		const value2 = data2?.data?.spotify_seo_name

		const score1 = value1 && value1.length > 0 ? nameScore(value1[0].data) : 0
		const score2 = value2 && value2.length > 0 ? nameScore(value2[0].data) : 0

		let result = null

		if (isNaN(score1) && !isNaN(score2)) {
			result = 1
		} else if (!isNaN(score1) && isNaN(score2)) {
			result = -1
		} else if (isNaN(score1) && isNaN(score2)) {
			result = 0
		} else {
			result = (score1 < score2) ? 1 : (score1 > score2) ? -1 : 0
		}
		return (event.order * result)
	})

	return playlists
}

export function seoBalancedSort (event, playlists) {
	playlists.sort((data1, data2) => {
		const value1 = data1?.data?.spotify_seo_name
		const value2 = data2?.data?.spotify_seo_name

		const score1 = value1 && value1.length > 0 ? nameScore(value1[0].data) : 0
		const score2 = value2 && value2.length > 0 ? nameScore(value2[0].data) : 0

		let result = null

		if (isNaN(score1) && !isNaN(score2)) {
			result = 1
		} else if (!isNaN(score1) && isNaN(score2)) {
			result = -1
		} else if (isNaN(score1) && isNaN(score2)) {
			result = 0
		} else {
			result = (score1 < score2) ? 1 : (score1 > score2) ? -1 : 0
		}
		return (event.order * result)
	})

	return playlists
}
